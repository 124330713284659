import Container from 'react-bootstrap/Container'
import { Logos } from 'src/@shared/types/cms/logos'
import { Parallax } from 'react-scroll-parallax'
import { getCMSImage } from 'src/@shared/functions/format'
import { Image } from 'react-bootstrap'
import { useEffect, useState } from 'react'

import { useWindowMounted } from '@atk/hooks/useWindowMounted'

import styles from './LogosSlider.module.scss'

type animationType = 'parallax-left' | 'marquee'

export type logosSliderProps = {
    title?: string
    customClass?: string
    logoImg: Logos[]
    animation: animationType
}

export default function LogosSlider({ title, logoImg, customClass, animation }: logosSliderProps) {
    const componentClass = `logosSlider ${styles.logosSlider} ${customClass ?? ''}`
    const [animationDuration, setAnimationDuration] = useState(30)
    const { hasWindow } = useWindowMounted()
    const animations = `scroll-${logoImg.length} ${animationDuration}s linear infinite`
    const updateSize = () => {
        const duration = 1.82 * logoImg.length
        if (window.innerWidth < 768) setAnimationDuration(Math.floor(duration * 1.5))
        else if (window.innerWidth < 992) setAnimationDuration(Math.floor(duration * 1.25))
        else setAnimationDuration(Math.floor(duration))
    }
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', updateSize)
            updateSize()
        }
    }, [])

    return (
        <div className={componentClass}>
            <Container>
                <h3 className="mb-5">{title}</h3>
            </Container>
            <Container fluid>
                {animation === 'marquee' && hasWindow && (
                    <div className="slider">
                        <div style={{ animation: animations }} className="slideTrack">
                            {logoImg &&
                                logoImg.map((image) => (
                                    <div className="slide" key={image._id}>
                                        <Image src={getCMSImage(image.logoUrl)} alt={image.name} loading="lazy" />
                                    </div>
                                ))}
                            {logoImg &&
                                logoImg.map((image) => (
                                    <div className="slide" key={image._id}>
                                        <Image src={getCMSImage(image.logoUrl)} alt={image.name} loading="lazy" />
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
                {animation === 'parallax-left' && (
                    <Parallax translateX={[20, 0]}>
                        <div className="d-flex align-items-center">
                            {logoImg &&
                                logoImg.map((image) => (
                                    <div className="mx-5" key={image._id}>
                                        <Image src={getCMSImage(image.logoUrl)} alt={image.name} />
                                    </div>
                                ))}
                        </div>
                    </Parallax>
                )}
            </Container>
        </div>
    )
}
