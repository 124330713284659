import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'next/image'
import CareersImg from '@assets/img/careers-img.webp'
import CareersImgMobile from '@assets/img/careers-img-mobile.webp'
import Link from 'next/link'

export default function Careers() {
    return (
        <section className="careersSection">
            <Image
                src={CareersImgMobile.src}
                alt="Casper Labs"
                className="w-100 h-auto d-lg-none"
                width={500}
                height={500}
            />
            <div className="position-relative">
                <Row className="content g-0">
                    <Col className="mb-5 mb-lg-0 text" lg={5}>
                        <p className="half-title text-uppercase">Careers at casper labs</p>
                        <h2 className="section-title-h2 mb-4 h1">
                            Help us onboard the next 1 million blockchain users
                        </h2>
                        <p className="mb-5">
                            Casper Labs is writing blockchain’s next chapter and is looking for driven individuals who
                            want to join us on this ambitious journey.
                        </p>
                        <Link href="/careers?section=jobs">
                            <Button variant="secondary">Explore opportunities</Button>
                        </Link>
                    </Col>
                    <Col className="position-relative d-none d-lg-block ps-7" lg={7}>
                        <Image src={CareersImg.src} alt="Casper Labs" className="w-100" width={500} height={500} />
                    </Col>
                </Row>
            </div>
        </section>
    )
}
