import { useEffect, useState } from 'react'

export function useWindowMounted() {
    const [hasWindow, setHasWindow] = useState(false)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setHasWindow(true)
        }
    }, [])

    return { hasWindow }
}
