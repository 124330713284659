import { Container, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'

import FramerMotion from '@atk/hooks/framerMotion'

import styles from './CTARight.module.scss'

export type CTARightProps = {
    id?: string
    halfTitle?: string
    title: string
    desc?: string
    customClass?: string
    linkButton: { label: string; link: string | (() => void); variant: string; target?: string }
    col1?: number
    col2?: number
    yFrameMotion?: number
}

export default function CTARight({
    id,
    halfTitle,
    title,
    desc,
    customClass,
    linkButton,
    col1,
    col2,
    yFrameMotion,
}: CTARightProps) {
    const componentClass = `ctaRight ${styles.ctaRight}  ${customClass ?? ''}`

    return (
        <section id={id} className={componentClass}>
            <Container>
                <FramerMotion y={yFrameMotion}>
                    <Row className="align-items-center">
                        <Col md={col1 ? col1 : 6}>
                            {halfTitle && <p className="half-title">{halfTitle}</p>}
                            <h2 className="h3 mb-4 mb-lg-0">{title}</h2>
                            {desc && <p>{desc}</p>}
                        </Col>
                        <Col className="d-flex justify-content-end" md={col2 ? col2 : 6}>
                            <Button
                                variant={linkButton.variant}
                                target={linkButton.target ?? '_self'}
                                {...(typeof linkButton.link === 'string'
                                    ? { href: linkButton.link }
                                    : { onClick: linkButton.link })}
                            >
                                {linkButton.label}
                            </Button>
                        </Col>
                    </Row>
                </FramerMotion>
            </Container>
        </section>
    )
}
