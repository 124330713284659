import { Button, Col, Container, Row } from 'react-bootstrap'
// import EnergyIcon from '@assets/icons/energy-utilities.svg'
// import Financial from '@assets/icons/financial.svg'
// import Government from '@assets/icons/government.svg'
// import Health from '@assets/icons/health.svg'
// import Manufacturing from '@assets/icons/manufacturing.svg'
// import Media from '@assets/icons/media.svg'
// import Retail from '@assets/icons/retail.svg'
// import Technology from '@assets/icons/technology.svg'
// import Telecommunications from '@assets/icons/telecomunications.svg'

// import Carousel1 from '@atk/components/carousel/Carousel1/Carousel1'

import styles from './CarouselCasper.module.scss'

// const carousel = [
//     {
//         title: 'Energy & Utilities',
//         icon: EnergyIcon.src,
//     },
//     {
//         title: 'Financial Services',
//         icon: Financial.src,
//     },
//     {
//         title: 'Goverment & Public Sector',
//         icon: Government.src,
//     },
//     {
//         title: 'Healthcare & Life Sciences',
//         icon: Health.src,
//     },
//     {
//         title: 'Manufacturing & Supply Chain',
//         icon: Manufacturing.src,
//     },
//     {
//         title: 'Media & Entertainment',
//         icon: Media.src,
//     },
//     {
//         title: 'Retail',
//         icon: Retail.src,
//     },
//     {
//         title: 'Technology',
//         icon: Technology.src,
//     },
//     {
//         title: 'Telecommunications',
//         icon: Telecommunications.src,
//     },
// ]

export default function CarouselCasper() {
    const classHome = `carouselCasper ${styles.carouselHome}`
    return (
        <section className={classHome}>
            <Container>
                <Row>
                    <Col md={6} className="pt-3">
                        <p className="half-title">The Casper Labs difference</p>
                        <h2 className="section-title-h2 h1 mb-3 mb-lg-5">
                            How can <span className="text-green">Casper Labs</span> help?
                        </h2>
                    </Col>
                    <Col md={6}>
                        <hr className="mb-4" />
                        <p className="mb-4">
                            Blockchain technology should meet businesses where they are, not vice versa. Successful
                            outcomes require a high degree of flexibility and interoperability with your existing
                            systems. Unlike other “one-size-fits-all” blockchains, Casper delivers just that.
                        </p>
                        <Button href="/customer-stories" className="mb-lg-0 mb-3" variant="secondary">
                            SEE CASE STUDIES
                        </Button>
                    </Col>
                </Row>
            </Container>
            {/* <Carousel1 list={carousel} /> */}
        </section>
    )
}
