/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import Carousel from 'react-multi-carousel'
import { useId, useRef, useState, useEffect } from 'react'
import SacalableIcon from '@assets/icons/slide-scalable-icon.svg'
import FlexibleIcon from '@assets/icons/slide-flexible-icon.svg'
import InteroperabilityIcon from '@assets/icons/slide-interoperability-icon.svg'
import CustomizableIcon from '@assets/icons/slide-customizable-icon.svg'
import PredictableIcon from '@assets/icons/slide-predictable-icon.svg'
import SecurityIcon from '@assets/icons/slide-security-icon.svg'
import { setHorizontalScrollToElement } from 'src/@shared/functions/format'

import { Carousel1ResProps } from '@atk/components/carousel/Carousel1/Carousel1'
import Icon from '@atk/components/icon'

import FramerMotion from '@atk/hooks/framerMotion'

import styles from './SlideCasper.module.scss'

const responsive: Carousel1ResProps = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1200 },
        items: 3.6,
    },
    desktop: {
        breakpoint: { max: 1199.99, min: 992 },
        items: 2.5,
    },
    tablet: {
        breakpoint: { max: 991.99, min: 768 },
        items: 2.1,
    },
    mobile: {
        breakpoint: { max: 767.99, min: 0 },
        items: 1.2,
    },
}

export type SlideCasperState = {
    title: string
    desc: string
}

const getIcon = (index: number) => {
    switch (index) {
        case 0:
            return SacalableIcon.src
        case 1:
            return FlexibleIcon.src
        case 2:
            return InteroperabilityIcon.src
        case 3:
            return SecurityIcon.src
        case 4:
            return CustomizableIcon.src
        case 5:
            return PredictableIcon.src
        default:
            return ''
    }
}

const slide = [
    {
        title: 'Scalable',
        desc: 'The Casper protocol was built based on the energy-efficient, proof-of-stake CBC Casper specifications.',
    },
    {
        title: 'Flexible',
        desc: 'On Casper, you can seamlessly deploy and migrate data between fully public, hybrid and private environments.',
    },
    {
        title: 'Interoperable',
        desc: 'Casper is designed to plug into your existing technology stack, without the need to significantly re-engineer the back end.',
    },
    {
        title: 'Secure',
        desc: 'The Casper protocol features industry-leading security and control functionality, including highly configurable permission settings.',
    },
    {
        title: 'Customizable',
        desc: 'Casper is the only blockchain to offer upgradability, meaning that you can always update code to meet changing business requirements.',
    },
    {
        title: 'Predictable',
        desc: `Casper's gas model is not only significantly more cost-effective than alternatives, but also much more predictable - no surprise costs or spikes.`,
    },
]

export default function SlideCasper() {
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const key = useId()
    const ref = useRef(null)
    const title = `Casper is the market's most secure and `
    const titleClass = `${styles.sectionTitleSlide} mb-5 h1`
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const carousel = ref.current as any
    const handleCardClick = (index: number) => {
        if (index === selectedIndex) index = -1
        setSelectedIndex(index)
        for (let i = 0; i <= 5; i++) {
            carousel.listRef.current.children[i].classList.remove('cardActive')
            carousel.listRef.current.children[i].classList.remove('carouselCardActive' + i)
            if (index === i) {
                carousel.listRef.current.children[i].classList.add('cardActive')
                carousel.listRef.current.children[i].classList.add('carouselCardActive' + index)
            }
        }
    }

    useEffect(() => {
        if (carousel) {
            setHorizontalScrollToElement(carousel.containerRef.current)
        }
    }, [carousel])

    return (
        <section className="slideCasper">
            <FramerMotion>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <p className="half-title text-uppercase">About the Casper Blockchain</p>
                            <h2 className={titleClass}>
                                {title}
                                <span className="text-green">flexible blockchain</span>
                            </h2>
                        </Col>
                    </Row>
                    <Row className="mb-5 align-items-center">
                        <Col md={8}>
                            <p>
                                Casper is designed to accelerate enterprise and developer adoption of blockchain
                                technology today, while also maintaining the flexibility required to evolve and meet
                                user needs in the future.
                            </p>
                        </Col>
                        <Col md={4}>
                            <Button href="/company" className="ms-lg-4" variant="secondary">
                                LEARN MORE
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </FramerMotion>
            <div className={styles.cardsContainer}>
                <FramerMotion>
                    <Carousel ref={ref} responsive={responsive} arrows={false} swipeable={false} draggable={false}>
                        {slide &&
                            slide.map((p: SlideCasperState, index: number) => (
                                <div key={key + index} className="p-3">
                                    <Card
                                        onClick={() => handleCardClick(index)}
                                        className={'carouselCard' + (' carouselCard' + index)}
                                    >
                                        <Card.Body className={styles.cardBody}>
                                            <div
                                                className={
                                                    selectedIndex !== index
                                                        ? 'd-flex flex-column justify-content-end mb-5 mb-lg-6'
                                                        : 'd-flex flex-column justify-content-center h-100 px-lg-5'
                                                }
                                            >
                                                {selectedIndex === index && (
                                                    <div className="mb-5 mb-lg-7">
                                                        <Icon source={getIcon(index)} />
                                                    </div>
                                                )}

                                                <div className="">
                                                    <h3 className={selectedIndex !== index ? 'cardTitle' : 'mb-4'}>
                                                        {p.title}
                                                    </h3>
                                                    {selectedIndex === index && (
                                                        <Card.Text
                                                            className={selectedIndex !== index ? '' : 'cardContent'}
                                                        >
                                                            {p.desc}
                                                        </Card.Text>
                                                    )}
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                    </Carousel>
                </FramerMotion>
                <div className="carouselScrollLine"></div>
            </div>
        </section>
    )
}
