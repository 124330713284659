import { Button, Col, Container, Row } from 'react-bootstrap'
import { ReactNode } from 'react'
import Link from 'next/link'

import FramerMotion from '@atk/hooks/framerMotion'

import styles from './ContentRight.module.scss'

export type ContentRightProps = {
    halfTitle?: string
    title: string
    desc?: string
    customClass?: string
    ContentRightContent: ReactNode
    col1?: number
    col2?: number
    colLg1?: number
    colLg2?: number
    colXl1?: number
    colXl2?: number
    linkButton: { label: string; link: string | (() => void); variant: string }
    linkButton2?: { label: string; link: string; variant: string }
}

export default function ContentRight({
    halfTitle,
    title,
    desc,
    linkButton,
    linkButton2,
    customClass,
    col1,
    col2,
    colLg1,
    colLg2,
    colXl1,
    colXl2,
    ContentRightContent,
}: ContentRightProps) {
    const componentClass = `ContentRight ${styles.ContentRight} ${customClass ?? ''}`

    return (
        <section className={componentClass}>
            <div>
                <FramerMotion>
                    <Container>
                        <Row className="align-items-center g-6">
                            <Col md={col1} lg={colLg1} xl={colXl1}>
                                {halfTitle && <p className="half-title">A blockchain for the “other” 25M developers</p>}
                                <h2 className="section-title-h2 mb-4">{title}</h2>
                                {desc && <p>{desc}</p>}
                                <hr className="w-100 my-4" />
                                {typeof linkButton.link === 'string' ? (
                                    <Link href={linkButton.link}>
                                        <Button
                                            className={linkButton2 && 'me-3 mb-3 mb-lg-0'}
                                            variant={linkButton.variant}
                                        >
                                            {linkButton.label}
                                        </Button>
                                    </Link>
                                ) : (
                                    <Button
                                        onClick={() => {
                                            ;(linkButton.link as () => void)()
                                        }}
                                        className={linkButton2 && 'me-3 mb-3 mb-lg-0'}
                                        variant={linkButton.variant}
                                    >
                                        {linkButton.label}
                                    </Button>
                                )}
                                {linkButton2 && (
                                    <Link href={linkButton2.link}>
                                        <Button variant={linkButton2.variant}>{linkButton2.label}</Button>
                                    </Link>
                                )}
                            </Col>
                            <Col md={col2} lg={colLg2} xl={colXl2}>
                                <div className="position-relative contentRightContent">{ContentRightContent}</div>
                            </Col>
                        </Row>
                    </Container>
                </FramerMotion>
            </div>
        </section>
    )
}
