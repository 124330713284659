/* eslint-disable react/no-unescaped-entities */
import { useCallback, useEffect, useState } from 'react'
import HeroSection from '@modules/home/components/HeroFullCasper/HeroFullCasper'
import { QueryClient, dehydrate, useQuery } from 'react-query'
import { getBannerPage, getLegalDocs, getLogos } from 'src/@shared/service/cms-api'
import { useRouter } from 'next/router'
import { scrollObjFromAnchor, scrollToTargetAdjusted } from 'src/@shared/functions/animations'
import NewToBlockchain from '@assets/img/new-to-blockchain.webp'
import TrustedIcon from '@assets/icons/trusted-icon.svg'
import GetStarted from '@assets/img/get-started.webp'
import Container from 'react-bootstrap/Container'
import Image from 'next/image'
import { getSlides } from '@modules/common/components/BannerSlider'
import Careers from '@modules/home/components/Careers/Careers'
import CarouselCasper from '@modules/home/components/CarouselCasper/CarouselCasper'
import SlideCasper from '@modules/home/components/SlideCasper/SlideCasper'
import BannerSlider from '@modules/common/components/BannerSlider/BannerSlider'
import dynamic from 'next/dynamic'
import heroBgMobile from '@assets/img/home-hero-mobile-bg.webp'
import heroBg from '@assets/img/home-hero-bg.webp'

import CustomHead from '@atk/components/head/customHead/customHead'
import Icon from '@atk/components/icon'
import LogosSlider from '@atk/components/logos/LogosSlider/LogosSlider'
import Newsletter2 from '@atk/components/newsletter/Newsletter2/Newsletter2'
import CTARight from '@atk/components/cta/CTARight/CTARight'
import ContentRight from '@atk/components/content/ContentRight/ContentRight'
import ContentCenter from '@atk/components/content/ContentCenter/ContentCenter'
import HotspotModal from '@atk/components/layout/Modal/HotspotModal'
import AccordionComponent from '@atk/components/accordion/Accordion/Accordion'

import FramerMotion from '@atk/hooks/framerMotion'

const VideoPlayer = dynamic(() => import('@atk/components/video/VideoPlayer/VideoPlayer'), {
    ssr: false,
})

const initialCatArgs = { sort: 'position', limit: 0 }

export async function getServerSideProps() {
    const queryClient = new QueryClient()

    await Promise.all([
        queryClient.fetchQuery(['legaldocs'], () => getLegalDocs({ sort: 'position', limit: 0, page: 1 })),
        queryClient.fetchQuery(['logos'], () => getLogos(initialCatArgs)),
        queryClient.fetchQuery(['banner-home'], () =>
            getBannerPage({ sort: 'position', limit: 0, slug: 'banner-home' })
        ),
    ])

    // prefetch data on the server

    return {
        props: {
            // dehydrate query cache
            dehydratedState: dehydrate(queryClient),
        },
    }
}

export default function Home() {
    const [showEbookModal, setShowEbookModal] = useState(false)
    const handleCloseEbookModal = () => setShowEbookModal(false)
    const MeetCasperTitle = `Realize the <span>impact</span> you expect from blockchain projects`
    const transcription = `<p><span>(00:01)</span> <span>[Music]</span> I'm Medha Parlikar, co-founder of Casper and the Chief Technology Officer at Casper Labs. I spent much of my career in professional Enterprises like Adobe and Avalara, where I led developer teams and oversaw major technology decisions. What does blockchain for business mean? Well, first and foremost, a blockchain has to interoperate with legacy technology. Basically everything that’s been built before. From developer workflows to security requirements. We designed the Casper technology to work with existing infrastructure and the existing organization.</p>
    <p class="mt-3"><span>(00:40)</span> So instead of having an ivory tower of blockchain experts, organizations can onboard all of their developers. Flexibility is also essential for organizations. I often say to enterprise customers, history is immutable but the future is unwritten, and having upgradeable Smart Contracts gives them that flexibility. With Casper Smart Contract versioning is built in. While with other blockchains, Smart Contract upgrades are essentially a hack.</p>
    <p class="mt-3"><span>(01:35)</span> If you are a business looking to build a solution with real utility, The Casper protocol gives you all the flexibility and features your organization requires to make it a reality. And with Casper Labs you will have the support and service you expect when adopting a new technology. Come build with us.</p>`
    const AccordionItems = [
        {
            title: 'Video Transcript | Click to Expand',
            desc: `<P>What makes Casper Labs a Blockchain for Business</P>
            <p class="pb-3">Medha Parlikar | Casper Labs CTO</p>${transcription}`,
        },
    ]
    const router = useRouter()
    const getOffset = useCallback((section: string) => {
        switch (section) {
            case 'newsletter':
            case 'contact':
            case 'blockchain-data-report':
                return 150
            case 'meet-casper-labs':
                return 90
            default:
                return 120
        }
    }, [])
    const getOffsetAnchor = useCallback((path: string) => {
        const splittedRoute = path.split('#')
        if (splittedRoute.length == 2) {
            return getOffset(splittedRoute[1])
        }
    }, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            scrollObjFromAnchor(router.asPath, getOffsetAnchor(router.asPath))
            if (router.query.section) {
                scrollToTargetAdjusted(router.query.section.toString(), getOffset(router.query.section.toString()))
            }
        }, 1500)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    const { data: logos } = useQuery(['logos'], () => getLogos(initialCatArgs))
    const { data: banners } = useQuery(['banner-home'], () =>
        getBannerPage({ sort: 'position', limit: 0, slug: 'banner-home' })
    )
    const pageClass = `homePage`
    const isMobile = typeof window !== 'undefined' ? window.innerWidth <= 768 : false
    const bgImage = isMobile ? heroBgMobile.src : heroBg.src
    return (
        <>
            <CustomHead
                title="The Most Flexible Blockchain for Businesses | Casper Labs"
                description="Casper Labs powers scalable, secure & decentralized blockchain solutions for businesses, with the most flexible and customizable architecture on the market."
            >
                <link rel="preload" href={bgImage} as="image" />
            </CustomHead>
            <FramerMotion animation="fadeIn">
                <div className={pageClass}>
                    <HeroSection bgImage={bgImage} />
                    {banners && banners.length > 0 && (
                        <BannerSlider id="blockchain-data-report" interval={5000} slides={getSlides(banners)} />
                    )}
                    <HotspotModal
                        formId="42d4778a-690e-4d4e-b912-1d441d05caea"
                        target="#ebookForm"
                        showModal={showEbookModal}
                        handleClose={handleCloseEbookModal}
                    >
                        <div id="ebookForm"></div>
                    </HotspotModal>
                    <div className="overflow-hidden" id="meet-casper-labs">
                        <FramerMotion>
                            <ContentCenter
                                colWidth={8}
                                halfTitle="Meet Casper Labs"
                                title={MeetCasperTitle}
                                desc="Casper Labs provides enterprise-grade software and professional services to organizations
                    building on the Casper Blockchain. We're committed to setting you up for successful
                    business outcomes, on your terms – all powered by a team with deep enterprise experience."
                                linkButton2={{ label: 'LEARN MORE', link: '/company', variant: 'secondary' }}
                                customClass="pb-0"
                                ContentCenterChild={
                                    <div>
                                        <VideoPlayer
                                            isYoutube
                                            customClass="videoPlayerHome"
                                            controls
                                            width="100%"
                                            height="calc(600px + (1000 - 600) * ((100vw - 1440px) / (2560 - 1440)))"
                                            source="6BZ-Tja2ShU"
                                            title="What makes Casper Labs a Blockchain for Business"
                                        />
                                    </div>
                                }
                            />
                        </FramerMotion>
                    </div>
                    <Container>
                        <AccordionComponent items={AccordionItems} customClass="pt-0 pb-5 pb-lg-8" colDesc={12} />
                    </Container>
                    <CTARight
                        id="start-building"
                        col1={8}
                        col2={4}
                        halfTitle="A blockchain for the “other” 25M developers"
                        title="Learn why Casper is the most customizable blockchain on the market."
                        linkButton={{
                            label: 'Start Building',
                            link: 'https://docs.casperlabs.io/',
                            variant: 'secondary',
                            target: '_blank',
                        }}
                    />
                    {logos && (
                        <div className="overflow-hidden logosSliderSection" id="partners">
                            <FramerMotion>
                                <Container>
                                    <section className="pt-5 pb-0 pt-lg-6 pb-lg-3">
                                        <div className="d-flex align-items-center">
                                            <h3 className="mb-0">A trusted partner</h3>
                                            <div className="ms-3">
                                                <Icon source={TrustedIcon.src} />
                                            </div>
                                        </div>
                                    </section>
                                </Container>
                                <section className="pb-5 pt-0 pb-lg-6">
                                    <LogosSlider logoImg={logos.data} animation="marquee" />
                                </section>
                            </FramerMotion>
                        </div>
                    )}
                    <div id="casper-labs-difference">
                        <FramerMotion>
                            <CarouselCasper />
                        </FramerMotion>
                    </div>
                    <div id="ebook">
                        <ContentRight
                            customClass="py-5 py-lg-7"
                            colXl1={5}
                            colXl2={7}
                            title="New to blockchain?"
                            desc="Here’s a primer on what you need to know as you explore your options."
                            linkButton={{
                                label: 'get the ebook',
                                link: () => {
                                    setShowEbookModal(true)
                                },
                                variant: 'primary',
                            }}
                            ContentRightContent={
                                <Image alt="Casper Labs" src={NewToBlockchain.src} width={500} height={500} />
                            }
                        />
                    </div>
                    <div id="casper-blockchain">
                        <SlideCasper />
                    </div>
                    <div id="contact">
                        <ContentRight
                            customClass="getStarted py-5 pt-lg-6"
                            colXl1={6}
                            colXl2={6}
                            title="Ready to get started?"
                            desc="We'll help get you where you want to go with blockchain"
                            linkButton={{
                                label: 'Contact Us',
                                link: '/contact?section=contactForm',
                                variant: 'primary',
                            }}
                            ContentRightContent={<Image alt="Casper" src={GetStarted.src} width={500} height={500} />}
                        />
                    </div>
                    <div id="careers">
                        <FramerMotion>
                            <Careers />
                        </FramerMotion>
                    </div>
                    <div id="newsletter">
                        <Newsletter2 col1={5} col2={7} title="Sign up for our newsletter!" />
                    </div>
                </div>
            </FramerMotion>
        </>
    )
}
