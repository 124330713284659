import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Lottie from 'lottie-light-react'
import blocksAnimation from '@assets/animations/blockAnim.json'
import Link from 'next/link'

import styles from './HeroFullCasper.module.scss'

function HeroFullCasper({ bgImage }: { bgImage: string }) {
    const componentClass = `heroFullCasper ${styles.heroFullCasper}`

    return (
        <section style={{ backgroundImage: `url("${bgImage}")` }} className={componentClass}>
            <Container>
                <Row className="gx-lg-7 flex-column-reverse flex-md-row align-items-md-start">
                    <Col md={8} xl={7}>
                        <h1 className={styles.sectionTitle}>
                            We built a <span className="text-green">blockchain</span> that makes sense for business
                        </h1>
                        <p className={styles.desc}>Casper Labs delivers blockchain solutions that adapt and endure.</p>

                        <Link href="/contact?section=contactForm">
                            <Button variant="secondary" className="me-3 mb-3">
                                Get in touch
                            </Button>
                        </Link>
                        <Button href="/professional-services" variant="outline" className="me-3 mb-3">
                            explore our services
                        </Button>
                    </Col>
                    <Col className="blocks-animation position-relative" md={4} xl={5}>
                        <Lottie animationData={blocksAnimation} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default HeroFullCasper
